.cms__mainImage {
  width: 50%;
}

.cms__tableImage {
  width: 50px;
}

.cms__TableImage {
  width: 50%;
  margin-bottom: 10px;
}

.cms__TableIcon {
  width: 100%;
  margin-bottom: 10px;
  align-self: center;
}

.cms-table .decline-btn {
  width: 120px;
  background-color: #be1515;
  color: white;
  border-radius: 10px;
  flex-wrap: wrap;
  padding: 10px;
}

.cms-table .decline-btn:hover {
  color: white;
}

.cms-table .accept-btn {
  width: 120px;
  background-color: #109c1b;
  color: white;
  border-radius: 10px;
  flex-wrap: wrap;
  padding: 10px;
}

.cms-table .accept-btn:hover {
  color: white;
}


.cms-table .decline-btn:hover {
  color: white;
}

.pick-image{
  width:150px !important;
  background-color: #044d81 !important;
  color:white !important;
}

.text-input-cms {
  width:300px !important;
  
}
