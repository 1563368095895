body {
  overflow-x: hidden;
}

.tipsAndPromo .table-responsive {
  overflow-x: hidden !important;
}

.custom-sidebar .mouse-cursor {
  cursor: pointer;
}

.logo img {
  width: 100px;
  /* background-color: #fff; */
}

/* login */
.custom-alignment {
  margin-top: 100px;
}
.login .btn-primary-custom {
  color: #fff;
  background-color: #044d81;
}
.login .logo {
  width: 200px;
  margin-bottom: 12px;
}

.newMentorRequests .btn-approved {
  color: #fff;
  background-color: #9dcb3b;
}

.newMentorRequests .btn-pending {
  color: #fff;
  background-color: #044d81;
}
/* .newMentorRequests .btn-decline {
    color: #fff;
    background-color: #be1515 !important; 
} */
.decline-color {
  background-color: #be1515 !important;
}
.newMentorRequests .pagination {
  float: right;
}

.Header .header-icon {
  font-size: 28px;
}

/* NewMentorRequestDetails screen*/
.newMentorRequestDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.newMentorRequestDetails .profile img {
  width: 200px;
}
.newMentorRequestDetails .aboutMe-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}
.newMentorRequestDetails .aboutMe-para {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 14px;
}
.newMentorRequestDetails .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

.newMentorRequestDetails .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.newMentorRequestDetails .keyword {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.newMentorRequestDetails .key-box {
  /* border-radius: 5px;
    padding: 10px 24px;
    color: #fff;
    background: yellowgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px; */

  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  font-family: Nexa Light;
  font-size: 12px;
  border: 1px solid #b7afaf;
  border-radius: 3px;
  color: #484646;
}
.newMentorRequestDetails .key-box p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newMentorRequestDetails .Mentor-kind {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}

.newMentorRequestDetails .kind-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  margin: 0px;
  color: #869494;
  font-weight: 400;
  margin-left: 14px;
}

.newMentorRequestDetails .pdf-container {
  font-size: 35px;
  font-family: Aileron-Regular;
  color: #868080;
  float: right;
  margin-right: 30px;
}

.newMentorRequestDetails .btn-approved {
  color: #fff;
  background-color: #9dcb3b;
  padding: 12px 22px;
}

.newMentorRequestDetails .btn-pending {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  margin-left: 32px;
}

.newMentorRequestDetails .approved-pending {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  float: right;
  margin-right: 40px;
}

/* ApprovedMentorDetails  screen*/
.ApprovedMentorDetails .cover-photo {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.ApprovedMentorDetails .profile {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  /* position: absolute;
  bottom: 338px;
  left: 27px; */
}
.ApprovedMentorDetails .rating {
  display: flex;
  margin-bottom: 10px;
  margin-top: -14px;
}
.ApprovedMentorDetails .rating-number {
  font-size: 16px;
  font-family: Aileron-Regular;
  margin: 4px 0 0 5px;
}
.ApprovedMentorDetails .lesson-mentees {
  margin-top: 5px;
}
.ApprovedMentorDetails .menteeRated-text {
  font-size: 13px;
  font-family: Aileron-Regular;
  color: #969090;
  margin: 0;
}

.ApprovedMentorDetails .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.ApprovedMentorDetails .aboutMe-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}
.ApprovedMentorDetails .aboutMe-para {
  font-family: Aileron-Regular;
  color: #969090;
}

.ApprovedMentorDetails .Mentor-kind {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}

.ApprovedMentorDetails .kind-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  margin: 0px;
  color: #869494;
  font-weight: 400;
  margin-left: 14px;
}

.ApprovedMentorDetails .keyword {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.ApprovedMentorDetails .key-box {
  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  font-family: Nexa Light;
  font-size: 12px;
  border: 1px solid #b7afaf;
  border-radius: 3px;
  color: #484646;
}
.ApprovedMentorDetails .key-box p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApprovedMentorDetails .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

/* Mentor Changes Details */
.MentorChangesDetails .cover-btn {
  float: right;
  width: 150px;
  margin-right: 1px;
  background-color: #044d81;
}
.MentorChangesDetails .profile-btn {
  width: 150px;
  margin-top: 20px;
  margin-right: 1px;
  background-color: #044d81;
}

.MentorChangesDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.MentorChangesDetails .profile img {
  width: 200px;
}

.MentorChangesDetails .profile {
  text-align: center;
}

.MentorChangesDetails .editName-container {
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.MentorChangesDetails .inputEdit-text {
  border-radius: 5px;
  width: 260px;
}

.MentorChangesDetails .display-name {
  font-weight: 700;
  font-size: 18px;
  font-family: Aileron-Regular;
  padding: 8px 22px;
  color: #777;
}

.MentorChangesDetails .save-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  float: right;
  margin-right: 40px;
}

.MentorChangesDetails .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  margin-left: 32px;
  /* width: 200px; */
}

/* All Mentees  */
.table-img img {
  width: 50px;
}

/* Disputed Session */
.disputedSession .pagination {
  float: right;
}

/* Disputed Session Details  screen*/
.DisputedSessionDetail .profile img {
  width: 200px;
}

.DisputedSessionDetail .lesson-mentees {
  margin-top: 5px;
}
.DisputedSessionDetail .menteeRated-text {
  font-size: 13px;
  font-family: Aileron-Regular;
  color: #969090;
  margin: 0;
}

.DisputedSessionDetail .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.DisputedSessionDetail .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

.DisputedSessionDetail h2 .name-type {
  font-size: 16px;
  font-weight: 300;
}

.DisputedSessionDetail .disputedSection {
  margin-top: 28px;
}
.DisputedSessionDetail .disputedSection-text {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 20px;
}
.DisputedSessionDetail .disputedSection-subText {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 15px;
}
.DisputedSessionDetail .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DisputedSessionDetail .btn-Fav-MenteeMentor {
  color: #fff !important;
  background-color: #044d81 !important;
  padding: 12px 22px !important;
  /* margin-left: 32px !important; */
}

/* Feature add lang */
.featureLang .addBtn i {
  font-size: 22px;
  color: #2aad12;
  margin-top: 6px;
}

.featureLang .activeBtn {
  background-color: #044d81;
  padding: 10px 12px;
  width: 150px;
}
.featureLang .inactiveBtn {
  background-color: #be1515;
  padding: 10px 12px;
  width: 150px;
}

/* add language modal */
.custom-addLang-modal .addlang-modalBody {
  /* width: 200px; */
  height: 300px;
  padding: 50px 50px;
}
.custom-addLang-modal .tipsAndPro-modalBody {
  /* width: 200px; */
  /* height: 300px; */
  padding: 50px 50px;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}
.custom-addLang-modal input {
  /* border-color: #dce0eb;
    width: 240px;
    height: 38px;
    padding: 2px 12px;
    border-radius: 8px;
    font-size: 15px;
    margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 74%;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal h2 {
  /* margin-bottom: 28px; */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  width: 170px;
  border-radius: 20px;
}

.custom-addLang-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}

.custom-addLang-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.custom-addLang-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.modal-content {
  /* background-color: #223d64 !important; */
}

.modal-savebtn {
  color: rgb(3, 3, 3) !important;
  background-color: #ffffff !important;
  padding: 12px 22px !important;
  width: 170px !important;
  border-radius: 20px !important;
}

.modal-savebtn:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #b6df9b !important;
  padding: 12px 22px !important;
  width: 170px !important;
  border-radius: 20px !important;
}
.modal-savebtn:active {
  color: rgb(255, 255, 255) !important;
  background-color: #b6df9b !important;
  padding: 12px 22px !important;
  width: 170px !important;
  border-radius: 20px !important;
}

.cancelModal i {
  color: #c23b3b;
  float: right;
  margin-right: 10px;
  font-size: 20px;
  margin-top: 10px;
}
.footer-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.on-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control-advNotice {
  /* margin-left: 4px; */
  display: block !important;
  width: 34% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border-radius: 12px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  height: 42px;
}
.adv-update {
  width: 150px;
  border-radius: 30px !important;
  height: 43px;
  margin-bottom: 16px;
}
.prioritybtn {
  color: rgb(255 255 255) !important;
  background-color: #90dc74 !important;
  padding: 12px 22px !important;
  width: 170px !important;
  border-radius: 20px !important;
}

.priority-title {
  margin-top: 90px;
  text-align: center;
  color: #a2a2a2;
  font-size: 28px;
}
.notice-img {
  width: 200px;
}

.commission-sec {
  margin-top: 86px;
}
.commission-text {
  font-weight: 600;
  text-align: center;
}
.commission-title {
  font-weight: bold;
  font-size: 20px;
  color: #0d70fd;
}

.advanceNotice-searchBox {
  /* width: 32%; */
  width: 366px;
  border: 1px solid #a1a1a1;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.advanceNotice-searchBtn {
  border: 1px solid #a1a1a1;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.advanceNotice {
  /* overflow-y: scroll;
  height: 74vh; */
}

.advanceNotice .notFound i {
  font-size: 40px;
  color: #c8c6c6;
}
.advanceNotice .notFound-Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.advanceNotice .text-notFound {
  text-align: center;
  margin-bottom: 40px;
}

.tableinput-advNotice {
  /* margin-left: 4px; */
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border-radius: 12px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  height: 42px;
}

.Report-Modal {
  height: 465px !important;
}
.Report-Modal .TextBox {
  margin: 15px 0;
  padding: 13px 10px;
  width: 86%;
  height: 100px;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.Report-Modal .inputBox {
  margin: 15px 0;
  padding: 13px 10px;
  width: 86%;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.report-action {
  width: 136px;
}

.deactivateProfile img {
  /* width: 100%; */
  width: 200px;
  border-radius: 50%;
}

.activate-Button {
  width: 200px;
}

.MentorChangesDetails .displayPhoto img {
  width: 160px;
  border-radius: 50%;
  position: relative;
  /* top: -90px; */
  left: 28px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mentorChange-p {
  /* font-size: 22px;
    color: #a3a1a1;
    font-weight: 400; */
}
.mentorChange-name {
  font-size: 28px;
  font-weight: 500;
  color: #6c6868;
}

.mainHeading {
  font-size: 22px;
  color: #8c8c8c;
  text-align: center;
  margin-bottom: 12px;
}

.edit-Btn {
  width: 124px;
  float: right;
  margin-bottom: 14px;
}

.delete-Btn {
  width: 150px;
  float: right;
  margin-bottom: 14px;
}

.editMenteeDetail-Body {
  height: 90vh !important;
  width: 100% !important;
  padding: 50px 50px;
  overflow-y: scroll;
}

.scrollbar {
  /* margin-left: 30px; */
  /* float: left; */
  height: 300px;
  width: 65px;
  /* background: #F5F5F5; */
  overflow-y: scroll;
  margin-bottom: 25px;
}

.force-overflow {
  min-height: 450px;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #F5F5F5; */
}

#style-4::-webkit-scrollbar {
  width: 10px;
  /* background-color: #F5F5F5; */
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  /* border: 2px solid #555555; */
}

.editMenteeDetail-Body-Inner input {
  margin: 15px 0 !important;
  padding: 13px 10px !important;
  width: 100% !important;
  outline: none !important;
  background-color: #fff !important;
  border: 1px solid #bbb !important;
  border-radius: 8px !important;
  display: inline-block !important;
  box-sizing: border-box !important;
  transition: 0.2s ease all !important;
}

.editMentee-textBox {
  margin: 15px 0;
  padding: 13px 10px;
  width: 100%;
  height: 100px;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

/* add language modal */
.custom-addLang-modal1 .addlang-modalBody {
  /* width: 200px; */
  /* height: 800px; */
  padding: 50px 50px;
}
.custom-addLang-modal1 .tipsAndPro-modalBody {
  /* width: 200px; */
  /* height: 300px; */
  padding: 50px 50px;
}

.custom-addLang-modal1 .innerContainer {
  text-align: center;
}
.custom-addLang-modal1 input {
  /* border-color: #dce0eb;
    width: 240px;
    height: 38px;
    padding: 2px 12px;
    border-radius: 8px;
    font-size: 15px;
    margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 74%;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal1 h2 {
  margin-bottom: 28px;
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal1 .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  width: 170px;
  border-radius: 20px;
}
.custom-addLang-modal1 .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.custom-addLang-modal1 .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}
.DatePickerBox {
  width: 300px;
  height: 55px;
  padding: 5px 15px;
  background-color: #fff;
}
.DatePicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages-label-style {
  text-transform: uppercase;
}

.Image-Cover-Border {
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.save-container {
  width: 144px;
  float: right;
  margin-bottom: 10px;
  margin-right: 50px;
  margin-top: 12px;
}
.datePicker-container {
  margin-bottom: 20px;
}

.saveBtn-Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-save {
  width: 200px;
  border-radius: 12px;
  height: 48px;
}

.admin-goodwill .giveaway-applied {
  background-color: #9dcb3b;
  color: white;
}

.admin-goodwill .giveaway-not-applied {
  background-color: #b4b4b4;
  color: black;
}

.sess-prob .appliedd {
  background-color: #9dcb3b;
  color: white;
}

.sess-prob .not-appliedd {
  background-color: red;
  color: white;
}

.sess-prob .add-problem {
  font-size: 40px;
  cursor: pointer;
  color: #2aad12;
}

.add-sess-prob-modal input {
  margin: 15px 0;
  padding: 13px 10px;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.add-sess-prob-modal h2 {
  margin-bottom: 28px;
  color: #3656b1;
  font-size: 28px;
  font-weight: 400;
}

.add-sess-prob-modal.btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  width: 170px;
  border-radius: 20px;
}
.add-sess-prob-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.add-sess-prob-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.add-sess-prob-modal .addlang-modalBody {
  width: 750px;
  padding: 50px 50px;
}
.add-sess-prob-modal .tipsAndPro-modalBody {
  /* width: 200px; */
  /* height: 300px; */
  padding: 50px 50px;
}

.add-sess-prob-modal .innerContainer {
  text-align: center;
}

.add-sess-prob-modal .modal-content {
  width: 750px;
}

.pref-opt {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.add-div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.add-sess-prob-modal .add-confirm-ques {
  background-color: #223d64 !important;
  width: 150px;
  height: 50px;
  border: 1px solid white;
  color: white;
}

.add-sess-prob-modal .cancel-confirm-ques {
  background-color: white !important;
  width: 150px;
  height: 50px;
  border: 1px solid #223d64;
  color: #223d64;
}

.add-sess-prob-modal .add-confirm-ques.btn:hover {
  color: white;
}

.default-case {
  color: white;
  font-size: 17px;
  margin-bottom: 0;
}

.newMentorRequests .wait-call-pay {
  margin: 0;
  font-weight: bold;
  font-size: 17px;
}
.ApprovedMentorDetails .profile img {
  width: 100%;
}

.dispute-res-Modal {
  height: 700px !important;
}

.dispute-res-Modal .TextBox {
  margin: 15px 0;
  padding: 13px 10px;
  width: 86%;
  height: 80px;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.dispute-res-Modal .inputBox {
  margin: 15px 0;
  padding: 13px 10px;
  width: 86%;
  outline: none;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.disp-res .innerContainer {
  text-align: left;
}

.profile-newMentor {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.delete-modal.modal-content {
  width: 500px;
  /* padding: 50px 50px; */
}

.delete-modal-body {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.delete-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  width: 170px;
  border-radius: 20px;
}
.delete-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.delete-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.confirmText {
  color: rgb(90, 80, 80);
}

.stats_csv .stats-btn {
  width: 250px;
  background-color: #044d81;
  color: white;
  border-radius: 20px;
  flex-wrap: wrap;
  padding: 10px;
}

.stats_csv .stats-btn:hover {
  color: white;
}

.date-picker-stats {
  z-index: 999;
}

.blocked-users .block-btn {
  width: 120px;
  background-color: #044d81;
  color: white;
  border-radius: 7px;
  flex-wrap: wrap;
  padding: 9px;
}

.blocked-users .block-btn:hover {
  color: white;
}

.block-input {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
}

.ApprovedMentorDetails .giveaway-applied {
  background-color: #9dcb3b;
  color: white;
}

.payment-req .stats-btn {
  width: 250px;
  background-color: #044d81;
  color: white;
  border-radius: 20px;
  flex-wrap: wrap;
  padding: 10px;
}

.payment-req .stats-btn:hover {
  color: white;
}

.payment-req input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(1.5);
  padding: 8px;
}

.payment-req .decline-btn {
  width: 150px;
  background-color: #be1515;
  color: white;
  border-radius: 10px;
  flex-wrap: wrap;
  padding: 10px;
}

.payment-req .decline-btn:hover {
  color: white;
}

.payment-req .accept-btn {
  width: 150px;
  background-color: #109c1b;
  color: white;
  border-radius: 10px;
  flex-wrap: wrap;
  padding: 10px;
}

.payment-req .accept-btn:hover {
  color: white;
}

.submit-btn {
  width: 120px !important;
  background-color: #044d81 !important;
  color: white !important;
  border-radius: 10px !important;
  flex-wrap: wrap !important;
  padding: 10px !important;
}

.submit-btn:hover {
  color: white !important;
}

.icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-upload-avatar > img {
  width: 40%;
}

.custom-addLang-modal .addlang-modalBody {
  /* width: 200px; */
  /* height: 368px;
    padding: 50px 50px; */
  /* height: 256px; */
  height: auto;
  padding: 10px 50px;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}
.custom-addLang-modal input {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal h2 {
  /* margin-bottom: 28px; */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal .btn-cancel {
  color: rgb(17, 17, 17);
  background-color: #eceff1;
  padding: 12px 22px;
  border: none;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-cancel :hover {
  color: rgb(17, 17, 17);
  background-color: #eceff1;
  padding: 12px 22px;
  border: none;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}

.custom-addLang-modal textarea {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.modal-Header {
  height: 90px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
}

.alert-modal-Header {
  height: 100px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
  flex-direction: column;
}
.alert-modal-Header > img {
  margin-top: 10px;
  object-fit: contain;
  height: 50px;
}
.alert-modal-Header > h2 {
  text-transform: uppercase;
}
.Alert-modal-message-container > p {
  text-transform: capitalize;
  color: black;
  padding: 46px 0px;
  font-size: larger;
}
.Alert-modal-message-container {
}

.image-upload-avatar {
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAA3CAYAAACo29JGAAAABHNCSVQICAgIfAhkiAAABHtJREFUaEPtmktS20AQhiOoAnY4Jwg5QeAEMSeIfQLsDY8V5ATYJ4hZ8djYnCDmBJgTxDkB5gSxlkCB8/+qGVdrkOTRY2RVgaq0sDyP/qYf09OS9wnX5eXl6Ww2a3met8Xfri7MMcHdPTo6GriaQ47rAayDB6dlTKbneH19bZcB6F1cXNwrjXUx6cgV5MrKSgtj75UJSM3NOCHAdrGazuCEhfiYblPN6VSDy4DrAoy+HWjRpYkuBe7g4KADTTKoOAVcGhy15hpwqXCuAZcO5xKwEnCuAEuHQ4Zydnh4eBK1nxbtg6XBnZ+ft7CR99MkCUgudvb398dp+pjpVymbOCdFNtSDwMcphO1y20jRPtS0NM2lEVBkM+7gYEpbq6urX9IIptu+vLw8IJ2bZOnrHA4T0Ol/ZRFO9GnCrIZpxygDrgGhfqcVTLT3kTc2siTjzuFyQOXu+gFnsYTvN1paLI6TJs7N8urqahupEgtHtaIIVHFopMcjBILOxKynOIfLkE0sXAPA3SCvZBQOLlXi8LFdhBbQOVy/3689PT1FJrgLKWIaQEsDubEDooFnU3O7cA6XFaCIfh9wFqv4PrcC+tzz8/M3iwXK28Q3z2zOzbKgxNkKHNHSkw2dw6l9rmclXb5GE8C1SoXLJ2++3lGak2dLmPGdzQyVDijY9EcoS0wBMt/4BRRrK6O1tbVuu91mmzdXJeFSZkcE68G0+Q4idFUODmDH0Jb2dR/aG6BqNpARlSYKijraneAOIjq1vL6+3pRajIVjaoQ+eU7i5kKOsbo7Sb5ilP+uYXIncSanx1FRvYPffC0WmiMJrogaimTxIexWnLAAq0NDt6rDtRlBkxZFRfYRAWXRN9Es2QlVrEKOPBsbG+MkLcAcb2FidfPkYBMV2UZaGpLxr0zQK+FzUmtaMGl2gB5Jn2P29Pj4uAdN38nnAKT2vusFqgSceEcQMkcBPQXgLkHUUYzmu4071F5obwqz/lwJOPHRwZs6J/4bq4g4hVab0BZrqQTzacYReSm3hk2+44+FUyuUpq6f6B4wlSlO4WdRjfRHB1EvPpQJcjOXSXwkmPK9wDRxN5OiJcNrod+nmAmy8KvgZUzc/9zXoLF73T7pIwHtd2jbXaS5IssM9IPIRHyR5lDu0D6m+eY+aFqCgIvXnG0ILqIdBJpgHL5wSfK5oDzP7EX7oA4yUgaM9Q+/a4k+V4TQtmNYRMu5jxk+GIqWajP/w2DDiloloqUM4VjxHV0h0xU4aGho7nOszJnPMQ7TxUal9jkjyg2x6k1brYugNM+FddSthOYooJFbdqKOMHHAyhwZdGrWuWXa1cvbXtZteNTBEeanxamAGuOHBAT7iz513acymtMLYxxUg4MozOwmwud+qA9g6+xrgvFZ5eAoVMJJnKUFpl6hK+7blqrD6UIQ0ynzegDUEHcv7sMCfikbJKaqGDPK6zcF9Of5sUUfwj3f1Blw9NiLzoa6nWdEqQJkK2yIO0TMOVCWUYNKrwqljDq5BssiQESfCZ6xFpK7IPwfmWKV/wWSsjAAAAAASUVORK5CYII=); */
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  /* height: 60%; */
  height: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-avatar > input[type="file"] {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.image-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-upload-avatar > img {
  top: 0;
  width: 100%;
}

.input-field-cms {
  border-radius: 3px;
  border: 1px solid black;
  font-size: 17px;
  padding-top: 3px;
  height: 50px;
  padding-bottom: 3px;
  padding-left: 10px;
}

.input-field-cms:focus {
  border: 1px solid black;
}

.forgot-password-login {
  font-size: 16px;
  cursor: pointer;
  color: #044d81;
}

.forgot-password-login:hover{
  text-decoration: underline;
}



.forgot-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  width: 170px;
  border-radius: 20px;
}
.forgot-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.forgot-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}


.forgot-modal-body {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* width: 600px !important; */
}

.email-forgot{
  font-size: 17px;
}

.input-forgot-email{
  border-radius: 7px;
  padding-left:10px;
  font-size: 17px;
  width:400px;
  padding-top:5px;
  padding-bottom:5px;
}

.forgot-pass-label{
  font-size: 20px;
  font-weight: bold;
}